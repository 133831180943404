import React from "react"
import BackgroundImage from "gatsby-background-image"

import { Heading } from "../../components/utils"

const Hero = ({ data }) => (
  <>
    <BackgroundImage
      Tag="section"
      className="hero"
      fluid={data.image.fluid}
      backgroundColor="#072837"
    >
      <div className="h-full overlay"></div>
    </BackgroundImage>
    <div className="container">
      {data.title && (
        <Heading
          title={data.title}
          subtitle={data.subtitleNode && data.subtitleNode.childMarkdownRemark.html}
          className="pt-12 lg:pt-24"
          center
        />
      )}
    </div>
  </>
)

export default Hero
