import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Hero from "./Hero"

import Layout from "../../components/Layout"
import Content from "../../components/Content"

const Index = ({ data }) => (
  <Layout transparentHeader>
    <HelmetDatoCms
      htmlAttributes={{
        lang: data.page.locale,
      }}
      seo={data.page.seoMetaTags}
    >
      {data.page._allSlugLocales.map((version, index) => (
        <link rel="alternate" hreflang={version.locale} href={`https://ikeys-realty.com/${version.locale}/${version.value}`} key={index} />
      ))}
    </HelmetDatoCms>
    <div className="page">
      <Hero data={data.page} />
      <Content content={data.page.content} />
    </div>
  </Layout>
)

export const query = graphql`
  query PageQuery($locale: String!, $slug: String!) {
    page: datoCmsPage(locale: { eq: $locale }, slug: { eq: $slug }) {
      ...Page
    }
  }
`

export default Index
