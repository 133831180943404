import React from "react"

import {
  Faq,
  Text,
  Team,
  Posts,
  Gallery,
  Video,
  Quote,
  Contact,
  Features,
  Locations,
  PropertiesSlider,
} from "./blocks"

const Content = ({ content }) => (
  <>
    {content.map((item, index) => (
      <div key={index}>
        {
          {
            features_section: <Features data={item} />,
            locations_section: <Locations data={item} />,
            posts_section: <Posts data={item} />,
            properties_slider: <PropertiesSlider data={item} />,
            gallery: <Gallery data={item} />,
            contact: <Contact data={item} />,
            quote: <Quote data={item} />,
            video: <Video data={item} />,
            text: <Text data={item} />,
            team: <Team data={item} />,
            faq: <Faq data={item} />,
          }[item.model && item.model.apiKey]
        }
      </div>
    ))}
  </>
)

export default Content
